import React from "react";
import "../styles/student.css";
import { Row, Col, Card, Button, Spin } from "antd";
import { translate } from "../../base/actions/translate";
import { Link } from "react-router-dom";
import moment from "moment";
import { getLoggedInUser } from "../../service/SessionStorageService";
import { generateAppNum } from "../util/util";
import slider1 from "../../assets/images/student-loggedinn.jpg";
import {
  APPRV,
  ATTMPT,
  DOWN,
  ENROL,
  INCOMP,
  NEW,
  REJECC,
  SMSS,
} from "../../sampoorna-siksha/utils/consts";
import Student_Identification from "../../assets/forms/Student_Identification.pdf";
import MSS_LOGO from "../../assets/images/MSS_Web_logo.png";
import MNN_LOGO from "../../assets/images/MNN_Web_logo.png";

class StudentDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUser: getLoggedInUser(),
    };
  }

  componentDidMount() {
    if (this.props.profile) {
      this.props.getApplication(this.props.profile.username, moment().year());
      this.props.getAddSchoolRequests(this.props.profile.id);
    }
  }

  downloadApplicationForm() {
    // window.open('/forms/Student_Identification.pdf','');
    const link = document.createElement("a");
    link.href = "";
    link.download = Student_Identification;
    link.dispatchEvent(new MouseEvent("click"));
  }

  showEdit = () => {
    let startDate = "";
    let endDate = "";
    const sysConfig = this.props.appConfig.systemConfig;
    for (const config of sysConfig) {
      if (config.configCd === "APP_START_DT") {
        startDate = moment(config.configVal);
      } else if (config.configCd === "APP_END_DT") {
        endDate = moment(config.configVal);
      }
    }

    // console.log(
    //   moment().isSameOrBefore(endDate),
    //   "-----------------------",
    //   moment().isSameOrAfter(startDate)
    // );
    return (
      startDate &&
      endDate &&
      moment().isSameOrAfter(startDate, "day") &&
      moment().isSameOrBefore(endDate, "day") &&
      (this.props.studentData.applicationForm.status === INCOMP ||
        this.props.studentData.applicationForm.status === NEW)
    );
  };

  showNew = () => {
    let startDate = "";
    let endDate = "";
    const sysConfig = this.props.appConfig.systemConfig;
    for (const config of sysConfig) {
      if (config.configCd === "APP_START_DT") {
        startDate = moment(config.configVal);
      } else if (config.configCd === "APP_END_DT") {
        endDate = moment(config.configVal);
      }
    }

    // console.log(
    //   moment().isSameOrBefore(endDate),
    //   "-----------------------",
    //   moment().isSameOrAfter(startDate)
    // );
    return (
      startDate &&
      endDate &&
      moment().isSameOrAfter(startDate, "day") &&
      moment().isSameOrBefore(endDate, "day")
    );
  };

  getApplEndDate = () => {
    let date = "";
    if (this.props.appConfig.systemConfig.length) {
      for (const config of this.props.appConfig.systemConfig) {
        if (config.configCd === "APP_END_DT") {
          date = moment(config.configVal).format("DD-MM-YYYY");
        }
      }
    }
    return date;
  };

  getExamDate = () => {
    let date = "";
    if (this.props.appConfig.systemConfig.length) {
      for (const config of this.props.appConfig.systemConfig) {
        if (config.configCd === "EXAM_DT") {
          date = moment(config.configVal).zone("+05:30").format("DD-MM-YYYY");
        }
      }
    }
    return date;
  };

  getExamTime = () => {
    let date = "";
    if (this.props.appConfig.systemConfig.length) {
      for (const config of this.props.appConfig.systemConfig) {
        if (config.configCd === "EXAM_DT") {
          date = moment(config.configVal).zone("+05:30").format("hh:mm A");
        }
      }
    }
    return date;
  };

  generateAddSchoolStatus = () => {
    const requests = this.props.studentData.addSchoolRequests;
    const html = [];
    if (requests.length) {
      const req = requests[requests.length - 1];
      if (req.status === "DENY") {
        html.push(
          <p>
            Your request to add <strong>{req.entity.configVal}</strong> to
            schools list has been <strong>Denied</strong>. We will get back to
            you soon.
          </p>
        );
      } else if (req.status === "APRV") {
        html.push(
          <p>
            Your request to add <strong>{req.entity.configVal}</strong> to
            schools list has been <strong>Approved</strong>. You can start
            filling your application.
          </p>
        );
      } else if (req.status === "PNDG") {
        html.push(<p>{translate("addSchoolSuccess")}</p>);
      }
    }

    return html;
  };

  render() {
    const application = this.props.studentData.applicationForm;
    return (
      <div>
        {/* <Carousel {...this.props} /> */}
        <img className="student-loggedinn-pic" width="100%" src={slider1}></img>
        {!this.props.studentData.loadApplication &&
          this.props.studentData.applicationForm.status === INCOMP && (
            <Card>
              {this.showEdit() && (
                <div className="incomplete-text">
                  {translate("incompleteForm")}
                </div>
              )}
              {!this.showEdit() && (
                <div className="status-text">
                  {translate("applicationnotOnTime")}
                </div>
              )}
            </Card>
          )}

        {!this.props.studentData.loadApplication &&
          this.props.studentData.applicationForm.status === ENROL && (
            <Card>
              <div className="status-text">
                {translate("studentSelectedToMss")}
              </div>
            </Card>
          )}

        {!this.props.studentData.loadApplication &&
          (this.props.studentData.applicationForm.status === SMSS ||
            this.props.studentData.applicationForm.status === ATTMPT) && (
            <Card>
              <div className="status-text">
                {translate("studentAttemtedExam")}
              </div>
            </Card>
          )}

        <Spin
          spinning={
            this.props.studentData.loadApplication || this.props.isLoading
          }
          delay="500"
        >
          {!this.props.studentData.loading &&
            this.props.studentData.addSchoolRequests.length > 0 && (
              <Card style={{ marginTop: "20px" }}>
                <div className="reject-text">
                  {this.generateAddSchoolStatus()}
                </div>
              </Card>
            )}
          {!this.props.studentData.loadApplication &&
            this.props.studentData.applicationForm.status > INCOMP &&
            this.props.studentData.applicationForm.status < REJECC && (
              <Card>
                <div className="incomplete-text">
                  <p className="app-no-disc">
                    {translate("appNo")}{" "}
                    <span className="app-no">
                      {" "}
                      {generateAppNum(
                        this.props.studentData.applicationForm
                      )}{" "}
                    </span>
                  </p>
                  <p>{translate("homePageAppComplete")}</p>
                  {this.props.studentData.applicationForm.status === NEW &&
                    this.showEdit() && <p>{translate("homePageEditMsg")}</p>}
                  <p>{translate("homePageViewForm")}</p>
                </div>
              </Card>
            )}
          {!this.props.studentData.loadApplication &&
            !this.props.studentData.applicationForm.applicationId && (
              <Row>
                <Card bordered={false} className="card-95 mg-l">
                  <h3>{translate("scholorshipSelectionMessage")}</h3>
                </Card>
              </Row>
            )}
          {!this.props.studentData.loadApplication &&
            !this.props.studentData.applicationForm.applicationId && (
              <Row>
                {this.showNew() && (
                  <Card bordered={false} className="card-95 mg-l">
                    <Row>
                      <Col xs={24} sm={6}>
                        <img
                          className="prog-logo"
                          src={MSS_LOGO}
                          alt="mss"
                        ></img>
                      </Col>
                      <Col xs={24} sm={16} style={{ paddingRight: "15px" }}>
                        {translate("sampoornaSikshaInfo")}
                        {this.getExamDate().length > 0 && (
                          <div style={{ marginTop: "10px" }}>
                            <strong>{translate("examDateAndTime")} :</strong>{" "}
                            {this.getExamDate()} {this.getExamTime()}
                          </div>
                        )}
                      </Col>
                      <Col xs={24} sm={2}>
                        <span>
                          <strong>
                            {translate("applicationEndDate")}: <br />{" "}
                            {this.getApplEndDate()}{" "}
                          </strong>
                        </span>
                        <Link to="/sampurnaSiksha/apply">
                          <Button
                            type="primary"
                            shape="round"
                            className="mss-apply"
                          >
                            Apply
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Card>
                )}
                <Card bordered={false} className="card-95 mg-l">
                  <Row>
                    <Col xs={24} sm={6}>
                      <img className="prog-logo" src={MNN_LOGO} alt="mnn"></img>
                    </Col>
                    <Col xs={24} sm={16}>
                      {translate("nipunyaNirman")}
                    </Col>
                    <Col xs={24} sm={2}>
                      <Button
                        type="primary"
                        shape="round"
                        disabled
                        className="mss-apply"
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Row>
            )}
          {!this.props.studentData.loadApplication &&
            this.props.studentData.applicationForm.status === REJECC && (
              <Card>
                <div className="reject-text">
                  <div>
                    <label>{translate("applicationNumber")}:</label>{" "}
                    {generateAppNum(application)}
                  </div>
                  <div>
                    <label>{translate("studentName")} :</label>{" "}
                    {application.studentSurName} {application.studentName}
                  </div>
                  <br></br>
                  {translate("applicationRejectMsg")}
                </div>
              </Card>
            )}
          {!this.props.studentData.loadApplication &&
            (this.props.studentData.applicationForm.status === APPRV ||
              this.props.studentData.applicationForm.status === DOWN) && (
              <Card>
                <div className="reject-text">
                  <div>
                    <label>{translate("applicationNumber")}:</label>{" "}
                    {generateAppNum(application)}
                  </div>
                  {application.hallTicket !== null && (
                    <div>
                      <label>{translate("hallTicketNumber")} :</label>{" "}
                      {application.hallTicket.hallTicketNo}
                    </div>
                  )}

                  {this.getExamDate().length > 0 && (
                    <div>
                      <label>{translate("examDateAndTime")} :</label>{" "}
                      {this.getExamDate()} {this.getExamTime()}
                    </div>
                  )}
                  <br></br>
                  {translate("applicationApproveMsg")}
                </div>
              </Card>
            )}
        </Spin>
      </div>
    );
  }
}

export default StudentDashboard;
